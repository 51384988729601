import React from "react";


import { FormattedMessage } from "react-intl";

const Alerts = ({}) => {
	return (
		<div className="alerts">
			<div className="todos">
				<p>
					<FormattedMessage id="todos" />
				</p>
				<h2 className="number">10</h2>
			</div>

			<div className="inicio">
				<p>
					<FormattedMessage id="aguardando_inicio" />
				</p>
				<h2 className="number">03</h2>
			</div>

			<div className="aprovacao">
				<p>
					<FormattedMessage id="aguardando_aprovacao" />
				</p>
				<h2 className="number">15</h2>
			</div>

			<div className="participantes">
				<p>
					<FormattedMessage id="aguardando_participantes" />
				</p>
				<h2 className="number">15</h2>
			</div>

			<div className="rejeitados">
				<p>
					<FormattedMessage id="rejeitado" />
				</p>
				<h2 className="number">15</h2>
			</div>
		</div>
	);
};

export default Alerts;
