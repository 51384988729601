import React, { useEffect, useState } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import "../assets/css/login.css";
import LogoLogin from "../assets/images/logo-login1.png";
import { Link } from "react-router-dom";

// import ptMessages from '../locales/pt.json';
// import enMessages from '../locales/en.json';
// import esMessages from '../locales/es.json';

// const messages = {
//     pt: ptMessages,
//     en: enMessages,
//     es: esMessages,
//   };

const Login = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	// const [locale, setLocale] = useState('pt'); // Idioma inicial como português

	// const switchLanguage = (lang) => {
	//     setLocale(lang); // Muda o idioma para o selecionado
	//   };

	const handleSubmit = async (e) => {
		e.preventDefault();
		// Simula a requisição de login

		const response = await fetch("https://api.newronix.com.br/api/auth/login", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ username, password }),
		});
		const data = await response.json();

		if (data.token) {
			localStorage.setItem("token", data.token); // Armazena o token
			navigate("/dash"); // Redireciona para a página Dash
		} else {
			alert("Login falhou!");
		}
	};

	return (
		// <IntlProvider locale={locale} messages={messages[locale]}>
		<div id="login-page">
			<div className="login-form-div">
				<form onSubmit={handleSubmit}>
					<img src={LogoLogin} alt="" />
					<h2>Faça seu login</h2>

					<div className="row">
						<div className="col-12 ">
							<div>
								<label htmlFor="username">Usúario</label>
								<input
									required
									type="text"
									name="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-12">
							<div>
								<label htmlFor="senha">Senha</label>
								<input
									required
									type="password"
									name="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<a className="d-block" href="">
						Esqueceu sua senha
					</a>

					{/* <input className="entrar" type="submit" value="Entrar" /> */}
					<a href="/dash" className="entrar" value="Entrar">
						Entrar
					</a>

					<Link className="d-block" to="/cadastro">
						Cadastrar novo Usuario
					</Link>
					<p className="suporte">
						Suporte:{" "}
						<a href="mailto:suporte@roma.com.br">suporte@roma.com.br</a>
					</p>
				</form>
			</div>
		</div>
		// </IntlProvider>
	);
};

export default Login;
