// Sidebar.js
import React from "react";
import { FormattedMessage } from "react-intl";
import logoLateralCompleto from "../assets/images/logo-lateral-completo.png";
import menuIcon from "../assets/images/menu-icon.png";
import Ordem_Producao1 from "../assets/images/ordem-producao-white.png";
import Solicitacao1 from "../assets/images/prescricao-white.png";
import edit from "../assets/images/edit.png";
import grafico from "../assets/images/grafico.png";
import Configuracao1 from "../assets/images/configuracao-white.png";
import Suporte1 from "../assets/images/suporte-white.png";
import Ajuda1 from "../assets/images/informacao-white.png";
import { Link } from "react-router-dom";

const Sidebar = ({
	isSidebarCollapsed,
	toggleMenu,
	toggleSubmenu,
	isSubmenuOpen,
}) => {
	return (
		<div
			id="sidebar"
			className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}
		>
			<Link className="menu-item logo-div">
				<img width="100" src={logoLateralCompleto} alt="Logo" />
			</Link>
			<Link className="menu-item" onClick={toggleMenu}>
				<img width="20" src={menuIcon} alt="Menu Icon" />
				<p>
					<FormattedMessage id="recolher_menu" />
				</p>
			</Link>
			<Link className="menu-item" to="/dash/">
				<img width="20" src={Ordem_Producao1} alt="Ordem de Produção" />
				<p>
					<FormattedMessage id="ordem_producao" />
				</p>
			</Link>
			<Link className="menu-item" to="/dash/prescricao">
				<img width="20" src={Solicitacao1} alt="Solicitação" />
				<p>
					<FormattedMessage id="prescricao" />
				</p>
			</Link>
			<Link className="menu-item" to="">
				<img width="20" src={edit} alt="Relatórios" />
				<p>
					<FormattedMessage id="relatorios" />
				</p>
			</Link>
			<Link className="menu-item">
				<img width="20" src={grafico} alt="Dashboard" />
				<p>
					<FormattedMessage id="dashboard" />
				</p>
			</Link>
			<div className="menu-item relatorio" onClick={toggleSubmenu}>
				<div>
					<img width="20" src={Configuracao1} alt="Configuração" />
					<p>
						<FormattedMessage id="configuracao" />
						<span className={`arrow ${isSubmenuOpen ? "open" : ""}`}>
							&#9654;
						</span>
					</p>
				</div>
				<div
					className="submenu"
					style={{
						display: isSubmenuOpen ? "block" : "none",
						maxHeight: isSubmenuOpen ? "1000px" : "0",
						transition: "max-height 0.3s ease-out",
					}}
				>
					<ul>
						<li>
							<Link to="">
								<FormattedMessage id="cliente" />
							</Link>
						</li>
						<li>
							<Link to="">
								<FormattedMessage id="produto" />
							</Link>
						</li>
						<li>
							<Link to="">
								<FormattedMessage id="usuario" />
							</Link>
						</li>
					</ul>
				</div>
			</div>
			<Link className="menu-item">
				<img width="20" src={Suporte1} alt="Suporte" />
				<p>
					<FormattedMessage id="suporte" />
				</p>
			</Link>
			<Link className="menu-item">
				<img width="20" src={Ajuda1} alt="Ajuda" />
				<p>
					<FormattedMessage id="ajuda" />
				</p>
			</Link>
		</div>
	);
};

export default Sidebar;
