import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import excluir from "../assets/images/Lixeira.png";
import view from "../assets/images/View.png";
import arrow from "../assets/images/arrow.png";
import { Link } from "react-router-dom";

const timelineSteps = [
	"iniciado",
	"aguardando_aprovacao",
	"separacao",
	"manipulacao_iniciada",
	"cobranca",
	"qualidade",
	"armazenamento",
	"finalizado",
];

const OrdemProducao = () => {
	const [isTableVisible, setIsTableVisible] = useState(true);
	const [isFormVisible, setIsFormVisible] = useState(true);

	const toggleTable = () => setIsTableVisible(!isTableVisible);
	const toggleForm = () => setIsFormVisible(!isFormVisible);

	const tableRows = Array(4).fill({
		cod: "001",
		modo: "Comum",
		status: "Aguardando",
		matricula: "0001",
		leito: "0123",
		centro_custo: "URGÊNCIA",
		paciente: "Antonio Carlos Mendes de Sousa",
		dt_criacao: "01/10/2024",
		dt_validade: "01/10/2024",
		medico: "Rodolfo Maia",
		dt_liberacao: "01/10/2024",
	});

	const tableRows2 = Array(5).fill({
		id: "001",
		medicamento: "Dipirona",
		dosagem: "20ml",
		unidade: "xxx",
		posologia: "Dipirona",
		cond_adm: "xxx",
		via_adm: "Via Oral",
		cat_medicamento: "Analgésico",
		velocidade_infusao: "15 mls",
	});

	const intl = useIntl(); // Hook para acessar a função formatMessage

	return (
		<div>
			{/* Timeline Section */}
			<div className="timeline">
				<div className="d-flex mb-4">
					<h4 className="me-4">
						<FormattedMessage id="ordem" />
					</h4>
					<h4 className="status">
						<FormattedMessage id="status" />: <FormattedMessage id="iniciada" />
					</h4>
					<h4 className="ciclo-de-vida">
						<FormattedMessage id="ciclo_de_vida" />
					</h4>
				</div>
				<ol>
					{timelineSteps.map((step, index) => (
						<li key={index} className={index === 0 ? "active" : ""}>
							<span>
								<FormattedMessage id={step} />
							</span>
						</li>
					))}
				</ol>
			</div>

			<div id="criar-ordem-producao" className="form-create active m-0">
				{/* Table Section */}
				<div className=" active">
					<div className="d-flex align-items-center">
						<div>
							<img src={arrow} width="15" onClick={toggleTable} alt="" />
							<h4 className="m-0">
								<FormattedMessage id="criar_ordem_producao" />
							</h4>
						</div>
						<div>
							<img src={excluir} alt="" width="20" />

							<Link to="/dash/criar-prescricao" className="active">
								+ <FormattedMessage id="criar_prescricao" />
							</Link>
						</div>
					</div>

					<div
						id="tableContent"
						className={`table-content ${isTableVisible ? "show" : ""}`}
					>
						<table>
							<thead>
								<tr>
									<td></td>
									<td></td>
									<td>
										<FormattedMessage id="cod" />
									</td>
									<td>
										<FormattedMessage id="modo" />
									</td>
									<td>
										<FormattedMessage id="status" />
									</td>
									<td>
										<FormattedMessage id="matricula" />
									</td>
									<td>
										<FormattedMessage id="paciente" />
									</td>
									<td>
										<FormattedMessage id="leito" />
									</td>
									<td>
										<FormattedMessage id="centro_custo" />
									</td>
									<td>
										<FormattedMessage id="dt_criacao" />
									</td>
									<td>
										<FormattedMessage id="dt_validade" />
									</td>
									<td>
										<FormattedMessage id="medico" />
									</td>
									<td>
										<FormattedMessage id="dt_liberacao" />
									</td>
								</tr>
							</thead>
							<tbody>
								{tableRows.map((row, index) => (
									<tr key={index}>
										<td>
											<input type="checkbox" />
										</td>
										<td>
											<img src={view} alt="" width="20" />
										</td>
										<td>{row.cod}</td>
										<td>{row.modo}</td>
										<td>{row.status}</td>
										<td>{row.matricula}</td>
										<td>{row.paciente}</td>
										<td>{row.leito}</td>
										<td>{row.centro_custo}</td>
										<td>{row.dt_criacao}</td>
										<td>{row.dt_validade}</td>
										<td>{row.medico}</td>
										<td>{row.dt_liberacao}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div id="criar-produto" className="form-create active">
				<div className="d-flex align-items-center">
					<div>
						<img src={arrow} width="15" onClick={toggleTable} alt="" />
						<h4 className="m-0">
							<FormattedMessage id="incluir_produto" />
						</h4>
					</div>
					<div>
						<div className="me-5">
							<input type="checkbox" name="" id="" />
							<label htmlFor="ignorar">
								<FormattedMessage id="ignorar_produto" />
							</label>
						</div>
						<a href="" className="active">
							<FormattedMessage id="incluir" />
						</a>
					</div>
				</div>

				<div className={`prescricao-form ${isFormVisible ? "show" : ""}`}>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "selecionar_produto",
								defaultMessage: "",
							}) + ":"
						}
						className="input span15"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "quantidade",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "dosagem",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "unidade",
								defaultMessage: "",
							}) + ":"
						}
						className="input span3"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "posologia",
								defaultMessage: "",
							}) + ":"
						}
						className="input span8"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "cond_adm",
								defaultMessage: "",
							}) + ":"
						}
						className="input span8"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "via_adm",
								defaultMessage: "",
							}) + ":"
						}
						className="input span6"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "cat_medicamento",
								defaultMessage: "",
							}) + ":"
						}
						className="input span8"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "velocidade_infusao",
								defaultMessage: "",
							}) + ":"
						}
						className="input span5"
					/>
				</div>
			</div>

			{/* Table Section */}
			<div className="tabela active">
				<div className="d-flex align-items-center">
					<div>
						<img src={arrow} width="15" onClick={toggleTable} alt="" />
						<h4 className="m-0">
							<FormattedMessage id="produtos" />
						</h4>
					</div>
					<div>
						<img src={excluir} alt="" width="20" />

						<a href="">
							<FormattedMessage id="finalizar_inclusao" />
						</a>
					</div>
				</div>

				<div
					id="tableContent"
					className={`table-content ${isTableVisible ? "show" : ""}`}
				>
					<table>
						<thead>
							<tr>
								<td></td>
								<td>
									<FormattedMessage id="qtd" />
								</td>
								<td>
									<FormattedMessage id="medicamento" />
								</td>
								<td>
									<FormattedMessage id="dosagem" />
								</td>
								<td>
									<FormattedMessage id="unidade" />
								</td>
								<td>
									<FormattedMessage id="posologia" />
								</td>
								<td>
									<FormattedMessage id="cond_adm2" />
								</td>
								<td>
									<FormattedMessage id="via_adm2" />
								</td>
								<td>
									<FormattedMessage id="cat_medicamento2" />
								</td>
								<td>
									<FormattedMessage id="velocidade_infusao" />
								</td>
							</tr>
						</thead>
						<tbody>
							{tableRows2.map((row, index) => (
								<tr key={index}>
									<td>
										<input type="checkbox" name="" id="" />
									</td>
									<td>{row.id}</td>
									<td>{row.medicamento}</td>
									<td>{row.dosagem}</td>
									<td>{row.unidade}</td>
									<td>{row.posologia}</td>
									<td>{row.cond_adm}</td>
									<td>{row.via_adm}</td>
									<td>{row.cat_medicamento}</td>
									<td>{row.velocidade_infusao}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div id="aprovar_ordem_producao" className="form-create active">
				<div className="row">
					<div className="col-3">
						<div className="d-flex align-items-center">
							<div>
								<img src={arrow} width="15" onClick={toggleTable} alt="" />
								<h4 className="m-0">
									<FormattedMessage id="aprovar_ordem_producao" />
								</h4>
							</div>
						</div>
						<div className="d-flex mt-4">
							<div>
								<input type="radio" name="aprovacao" id="aprovar" />
								<label htmlFor="aprovar" className="text-uppercase">
									<FormattedMessage id="aprovar" />
								</label>
							</div>

							<div>
								<input type="radio" name="aprovacao" id="reprovar" />
								<label htmlFor="reprovar" className="text-uppercase">
									<FormattedMessage id="reprovar" />
								</label>
							</div>

							<div>
								<input type="radio" name="aprovacao" id="cancelar" />
								<label htmlFor="aprovar" className="text-uppercase">
									<FormattedMessage id="cancelar" />
								</label>
							</div>
						</div>
					</div>
					<div className="col-9">
						<div className={`aprovacao-form ${isFormVisible ? "show" : ""}`}>
							<input
								type="text"
								placeholder={intl.formatMessage({
									id: "motivo",
									defaultMessage: "",
								})}
								className="input span7"
							/>
							<input
								type="text"
								placeholder={
									intl.formatMessage({
										id: "nome_do_aprovador",
										defaultMessage: "",
									}) + ":"
								}
								className="input span5"
							/>
							<input
								type="text"
								placeholder={
									intl.formatMessage({
										id: "observacao",
										defaultMessage: "",
									}) + ":"
								}
								className="input span7"
							/>
							<input
								type="password"
								placeholder={
									intl.formatMessage({
										id: "senha",
										defaultMessage: "",
									}) + ":"
								}
								className="input span3"
							/>

							<a href="" className="active botao span2" >
								<FormattedMessage id="proximo" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrdemProducao;
