import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import excluir from "../assets/images/Lixeira.png";
import arrow from "../assets/images/arrow.png";

const timelineSteps = ["incluir_paciente", "incluir_medicamento", "Concluido"];

const CriarPrescricao = () => {
	const [isTableVisible, setIsTableVisible] = useState(true);
	const [isFormVisible, setIsFormVisible] = useState(true);

	const toggleTable = () => setIsTableVisible(!isTableVisible);
	const toggleForm = () => setIsFormVisible(!isFormVisible);

	const tableRows = Array(5).fill({
		id: "001",
		medicamento: "Dipirona",
		dosagem: "20ml",
		unidade: "xxx",
		posologia: "Dipirona",
		cond_adm: "xxx",
		via_adm: "Via Oral",
		cat_medicamento: "Analgésico",
		velocidade_infusao: "15 mls",
	});

	const intl = useIntl(); // Hook para acessar a função formatMessage

	return (
		<div>
			{/* Timeline Section */}
			<div className="timeline">
				<div className="d-flex mb-4">
					<h4 className="me-4">
						<FormattedMessage id="nova_prescricao" />
					</h4>

					<h4 className="ciclo-de-vida">
						<FormattedMessage id="status" />{" "}
						<FormattedMessage id="incluir_produto" />
					</h4>
				</div>
				<ol>
					{timelineSteps.map((step, index) => (
						<li key={index} className={index === 1 ? "active" : ""}>
							<span>
								<FormattedMessage id={step} />
							</span>
						</li>
					))}
				</ol>
			</div>

			<div id="criar-prescricao" className="form-create active">
				<div className="d-flex align-items-center">
					<div>
						<img src={arrow} width="15" onClick={toggleTable} alt="" />
						<h4 className="m-0">
							<FormattedMessage id="criar_prescricao" />
						</h4>
					</div>
					<div>
						<a href="" className="active">
							+ <FormattedMessage id="anexar_prescricao" />
						</a>

						<a href="">
							<FormattedMessage id="editar" />
						</a>
						<a href="">
							<FormattedMessage id="cancelar" />
						</a>
						<a href="">
							<FormattedMessage id="salvar" />
						</a>
					</div>
				</div>

				<div className={`prescricao-form ${isFormVisible ? "show" : ""}`}>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "numero",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "modo",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "dt_atendimento",
								defaultMessage: "",
							}) + ":"
						}
						className="input span3"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "dt_validade",
								defaultMessage: "",
							}) + ":"
						}
						className="input span3"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "custo_total",
								defaultMessage: "",
							}) + ":"
						}
						className="input span3"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "centro_custo",
								defaultMessage: "",
							}) + ":"
						}
						className="input span6"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "status",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "matricula",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "paciente",
								defaultMessage: "",
							}) + ":"
						}
						className="input span6"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "leito",
								defaultMessage: "",
							}) + ":"
						}
						className="input span1"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "dt_internacao",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "cod_interface_atendimento",
								defaultMessage: "",
							}) + ":"
						}
						className="input span4"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "cid",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "conselho",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "profissional",
								defaultMessage: "",
							}) + ":"
						}
						className="input span8"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "especialidade",
								defaultMessage: "",
							}) + ":"
						}
						className="input span7"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "crm",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
				</div>
			</div>

			<div id="criar-medicamento" className="form-create active">
				<div className="d-flex align-items-center">
					<div>
						<img src={arrow} width="15" onClick={toggleTable} alt="" />
						<h4 className="m-0">
							<FormattedMessage id="incluir_medicamento" />
						</h4>
					</div>
					<div>
						<a href="">
							<FormattedMessage id="incluir" />
						</a>
					</div>
				</div>

				<div className={`prescricao-form ${isFormVisible ? "show" : ""}`}>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "selecionar_produto",
								defaultMessage: "",
							}) + ":"
						}
						className="input span15"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "quantidade",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "dosagem",
								defaultMessage: "",
							}) + ":"
						}
						className="input span2"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "unidade",
								defaultMessage: "",
							}) + ":"
						}
						className="input span3"
					/>
					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "posologia",
								defaultMessage: "",
							}) + ":"
						}
						className="input span8"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "cond_adm",
								defaultMessage: "",
							}) + ":"
						}
						className="input span8"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "via_adm",
								defaultMessage: "",
							}) + ":"
						}
						className="input span6"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "cat_medicamento",
								defaultMessage: "",
							}) + ":"
						}
						className="input span8"
					/>

					<input
						type="text"
						placeholder={
							intl.formatMessage({
								id: "velocidade_infusao",
								defaultMessage: "",
							}) + ":"
						}
						className="input span5"
					/>
				</div>
			</div>

			{/* Table Section */}
			<div className="tabela active">
				<div className="d-flex align-items-center">
					<div>
						<img src={arrow} width="15" onClick={toggleTable} alt="" />
						<h4 className="m-0">
							<FormattedMessage id="produtos" />
						</h4>
					</div>
					<div>
						<img src={excluir} alt="" width="20" />

						<a href="">
							<FormattedMessage id="proximo" />
						</a>
					</div>
				</div>

				<div
					id="tableContent"
					className={`table-content ${isTableVisible ? "show" : ""}`}
				>
					<table>
						<thead>
							<tr>
								<td>
									<FormattedMessage id="qtd" />
								</td>
								<td>
									<FormattedMessage id="medicamento" />
								</td>
								<td>
									<FormattedMessage id="dosagem" />
								</td>
								<td>
									<FormattedMessage id="unidade" />
								</td>
								<td>
									<FormattedMessage id="posologia" />
								</td>
								<td>
									<FormattedMessage id="cond_adm2" />
								</td>
								<td>
									<FormattedMessage id="via_adm2" />
								</td>
								<td>
									<FormattedMessage id="cat_medicamento2" />
								</td>
								
								
							</tr>
						</thead>
						<tbody>
							{tableRows.map((row, index) => (
								<tr key={index}>
									<td>{row.id}</td>
									<td>{row.medicamento}</td>
									<td>{row.dosagem}</td>
									<td>{row.posologia}</td>
									<td>{row.cond_adm}</td>
									<td>{row.via_adm}</td>
									<td>{row.cat_medicamento}</td>
									<td>{row.velocidade_infusao}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CriarPrescricao;
