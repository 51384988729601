import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { IntlProvider } from 'react-intl';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dash from './pages/dash';
import Prescricao from './pages/Prescricao';



const root = ReactDOM.createRoot(document.getElementById('root'));

// Importando as mensagens para a língua padrão (ex: português)

// Definindo a localidade padrão

const Router = createBrowserRouter([
  {
    path: "/dash",
    element: <Dash />,
    children: [
      {
        path: "prescricao",
        element: <Prescricao />
      }
    ]
  }
])

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
