import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import arrow from "../assets/images/arrow.png";
import Filtros from "../components/Filtros";
import { Link } from "react-router-dom";

const timelineSteps = [
	"iniciado",
	"aguardando_aprovacao",
	"separacao",
	"manipulacao_iniciada",
	"cobranca",
	"qualidade",
	"armazenamento",
	"finalizado",
];

const OrdemProducao = () => {
	const [isTableVisible, setIsTableVisible] = useState(true);

	const toggleTable = () => setIsTableVisible(!isTableVisible);

	const tableRows = Array(15).fill({
		id: "ID",
		ordem: "N.Ordem",
		descricao: "Descrição",
		item3: "Item 3",
		item4: "Item 4",
		item5: "Item 5",
	});

	return (
		<div>
			<Filtros />
			{/* Timeline Section */}
			<div className="timeline">
				<div className="d-flex mb-4">
					<h4 className="me-4">
						<FormattedMessage id="ordem" />
					</h4>
					<h4 className="status">
						<FormattedMessage id="status" />: <FormattedMessage id="iniciada" />
					</h4>
					<h4 className="ciclo-de-vida">
						<FormattedMessage id="ciclo_de_vida" />
					</h4>
				</div>
				<ol>
					{timelineSteps.map((step, index) => (
						<li key={index} className={index === 0 ? "active" : ""}>
							<span>
								<FormattedMessage id={step} />
							</span>
						</li>
					))}
				</ol>
			</div>

			{/* Table Section */}
			<div className="tabela active">
				<div className="d-flex align-items-center">
					<div>
						<img src={arrow} width="15" onClick={toggleTable} alt="" />
						<h4 className="m-0">
							<FormattedMessage id="todas" />
						</h4>
					</div>
					<div>
						<Link to="criar-ordem-producao" className="active">
							+ <FormattedMessage id="ordem_producao" />
						</Link>
					
						<a href="">
							<FormattedMessage id="editar" />
						</a>
						<a href="">
							<FormattedMessage id="cancelar" />
						</a>
					</div>
				</div>

				<div
					id="tableContent"
					className={`table-content ${isTableVisible ? "show" : ""}`}
				>
					<table>
						<thead>
							<tr>
								<td>ID</td>
								<td>N.Ordem</td>
								<td>Descrição</td>
								<td>Item 3</td>
								<td>Item 4</td>
								<td>Item 5</td>
							</tr>
						</thead>
						<tbody>
							{tableRows.map((row, index) => (
								<tr key={index}>
									<td>{row.id}</td>
									<td>{row.ordem}</td>
									<td>{row.descricao}</td>
									<td>{row.item3}</td>
									<td>{row.item4}</td>
									<td>{row.item5}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default OrdemProducao;
