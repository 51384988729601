import React, { useEffect, useState } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";

import ptMessages from "../locales/pt.json";
import enMessages from "../locales/en.json";
import esMessages from "../locales/es.json";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Alerts from "../components/Alerts";

import Ordem_Producao1 from "../assets/images/ordem_producao.png";
import Solicitacao1 from "../assets/images/Solicitacao.png";
// import logo from '../assets/images/logo-footer.png'
import logoFooter from "../assets/images/logo-footer.png";
import "../assets/css/style.css";

const messages = {
	pt: ptMessages,
	en: enMessages,
	es: esMessages,
};

const Dash = () => {
	const location = useLocation(); // Pega a localização atual da rota
	const [pageId, setPageId] = useState(""); // Estado para a chave da página
	const [image, setImage] = useState(null); // Estado para a imagem

	const getPageDetails = () => {
		switch (location.pathname) {
			case "/dash":
				return { id: "ordem_producao", image: Ordem_Producao1 }; // Chave fixada para Ordem de Produção
			case "/dash/prescricao":
			case "/dash/criar-prescricao":
				return { id: "prescricao", image: Solicitacao1 }; // Chave fixada para Prescrição
			default:
				return { id: "ordem_producao", image: Ordem_Producao1 }; // Se não corresponder a nenhuma rota
		}
	};

	const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

	const toggleMenu = () => {
		setIsSidebarCollapsed((prevState) => !prevState);
	};

	// Estado para controlar a visibilidade da tabela
	const [isTableVisible, setTableVisible] = useState(true);
	const [isTableActive, setTableActive] = useState(true);

	// Função que alterna a visibilidade da tabela e a classe 'active'
	const toggleTable = () => {
		setTableVisible(!isTableVisible);
		setTableActive(!isTableActive);
	};

	const [isUserMenuActive, setIsUserMenuActive] = useState(false);

	const toggleUserMenu = () => {
		setIsUserMenuActive((prevState) => !prevState);
	};

	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

	const toggleSubmenu = () => {
		setIsSubmenuOpen((prevState) => !prevState);
	};

	const [locale, setLocale] = useState("pt"); // Idioma inicial como português

	const switchLanguage = (lang) => {
		setLocale(lang); // Muda o idioma para o selecionado
	};

	useEffect(() => {
		const { id, image } = getPageDetails();
		setPageId(id);
		setImage(image);
	}, [location]); // Executa sempre que a localização mudar

	return (
		<IntlProvider locale={locale} messages={messages[locale]}>
			<Sidebar
				isSidebarCollapsed={isSidebarCollapsed}
				toggleMenu={toggleMenu}
				toggleSubmenu={toggleSubmenu}
				isSubmenuOpen={isSubmenuOpen}
			/>

			<div id="content">
				<div className="page-content">
					<Header
						isUserMenuActive={isUserMenuActive}
						toggleUserMenu={toggleUserMenu}
						switchLanguage={switchLanguage}
						userName="Zamite Chaves"
						userRole="Master"
					/>

					<div className="subtitle-div">
						<img src={image} width="20" alt="Home" />
						<p>
							<FormattedMessage
								id={pageId || "default.pageId"} // Fallback para um id padrão
								defaultMessage={pageId || "Página"} // Fallback para o nome da página
							/>
						</p>
					</div>

					<Alerts />

					<div className="content-body">
						<Outlet />
					</div>
				</div>
				<div className="footer">
					<img
						src={logoFooter}
						className="logo-footer"
						width="250"
						alt="Logo Roma"
					/>
				</div>
			</div>
		</IntlProvider>
	);
};
export default Dash;
