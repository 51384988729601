import React from 'react';
import filtros from '../assets/images/filtros.png'; // Ajuste o caminho da imagem
import buscar from '../assets/images/buscar.png'; // Ajuste o caminho da imagem

const Filtros = () => {
  return (
    <div className="filtros">
      <div className="radio">
        <div className="d-flex align-items-center">
          <img src={filtros} width="25" alt="Ícone de filtros" />
          <p className="m-0">Filtro</p>
        </div>
        <div>
          <input type="radio" name="filtro" id="todas" />
          <label htmlFor="todas">Todas</label>
        </div>
        <div>
          <input type="radio" name="filtro" id="oncologia" />
          <label htmlFor="oncologia">Oncologia</label>
        </div>
        <div>
          <input type="radio" name="filtro" id="nutricao" />
          <label htmlFor="nutricao">Nutrição Parental</label>
        </div>
        <div>
          <input type="radio" name="filtro" id="padrao" />
          <label htmlFor="padrao">Padrão</label>
        </div>
      </div>
      <div className="inputs">
        <input type="text" placeholder="Paciente" />
        <input type="text" placeholder="Dt. Criação" />
        <input type="text" placeholder="Até" />
        <input type="text" placeholder="Status" />
        <input type="text" placeholder="Médico" />
        <input type="text" placeholder="Centro de Custos" />
        <a className="d-flex align-items-center text-decoration-none">
          <img src={buscar} width="25" alt="Ícone de buscar" />
          <p className="mb-0 ms-2">Pesquisar</p>
        </a>
      </div>
    </div>
  );
};

export default Filtros;
