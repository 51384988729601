import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import arrow from "../assets/images/arrow.png";
import Filtros from "../components/Filtros";
import { Link } from "react-router-dom";

const timelineSteps = [
	"iniciado",
	"aguardando_aprovacao",
	"separacao",
	"manipulacao_iniciada",
	"cobranca",
	"qualidade",
	"armazenamento",
	"finalizado",
];

const Prescricao = () => {
	const [isTableVisible, setIsTableVisible] = useState(true);

	const toggleTable = () => setIsTableVisible(!isTableVisible);

	const tableRows = Array(5).fill({
		cod: "001",
		modo: "Comum",
		status: "Aguardando",
		matricula: "00001",
		paciente: "Antonio Carlos",
		leito: "0123",
		centro_custo: "Urgencia",
		dt_criacao: "01/10/2024",
		dt_validade: "01/10/2024",
		medico: "Rodolfo Maia",
		dt_liberacao: "01/10/2024",
	});

	return (
		<div>
			<Filtros />

			{/* Table Section */}
			<div className="tabela active">
				<div className="d-flex align-items-center">
					<div>
						<img src={arrow} width="15" onClick={toggleTable} alt="" />
						<h4 className="m-0">
							<FormattedMessage id="todas" />
						</h4>
					</div>
					<div>
						
						<Link to="/dash/criar-prescricao" className="active">
							+ <FormattedMessage id="criar_prescricao" />
						</Link>
						<a href="">
							<FormattedMessage id="editar" />
						</a>
						<a href="">
							<FormattedMessage id="cancelar" />
						</a>
					</div>
				</div>

				<div
					id="tableContent"
					className={`table-content ${isTableVisible ? "show" : ""}`}
				>
					<table>
						<thead>
							<tr>
								<td>Cod</td>
								<td>Modo</td>
								<td>status</td>
								<td>Matricula</td>
								<td>Paciente</td>
								<td>Leito</td>
								<td>Centro de Custo</td>
								<td>Dt. Criação</td>
								<td>Dt. Validade</td>
								<td>Medico</td>
								<td>Dt. Liberacao</td>
							</tr>
						</thead>
						<tbody>
							{tableRows.map((row, index) => (
								<tr key={index}>
									<td>{row.cod}</td>
									<td>{row.modo}</td>
									<td>{row.status}</td>
									<td>{row.matricula}</td>
									<td>{row.paciente}</td>
									<td>{row.leito}</td>
									<td>{row.centro_custo}</td>
									<td>{row.dt_criacao}</td>
									<td>{row.dt_validade}</td>
									<td>{row.medico}</td>
									<td>{row.dt_liberacao}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Prescricao;
