import React, { useState } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import "../assets/css/cadastro.css";
import LogoLogin from "../assets/images/logo-login1.png";

const Register = () => {
	const [firstName, setFirstName] = useState(""); // Nome
	const [lastName, setLastName] = useState(""); // Sobrenome
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [username, setUsername] = useState(""); // Nome de usuário
	const [prefLanguage, setPrefLanguage] = useState("pt"); // Idioma preferido
	const [CelPhone, setCelPhone] = useState(""); // Celular
	const [NumDocument, setNumDocument] = useState(""); // CPF
	const [DateBirth, setDateBirth] = useState(""); // Data de nascimento
	const [typeDocument, setTypeDocument] = useState("CPF"); // Tipo de documento
	const [contryCelPhone, setContryCelPhone] = useState("BR"); // País do celular
	const [error, setError] = useState(""); // Mensagem de erro
	const [success, setSuccess] = useState(""); // Mensagem de sucesso
	const navigate = useNavigate();
  
	const handleSubmit = async (e) => {
	  e.preventDefault();
  
	  if (password !== confirmPassword) {
		setError("As senhas não coincidem.");
		setSuccess(""); // Limpa mensagem de sucesso
		return;
	  }
  
	  const completeName = `${firstName} ${lastName}`;
	  const userData = {
		completeName,
		username,
		email,
		password,
		prefLanguage,
		CelPhone,
		NumDocument,
		DateBirth,
		contryCelPhone,
		typeDocument,
	  };
  
	  try {
		const response = await fetch(
		  "https://api.newronix.com.br/api/auth/register",
		  {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			},
			body: JSON.stringify(userData),
		  }
		);
  
		const contentType = response.headers.get("content-type");
  
		if (contentType && contentType.includes("application/json")) {
		  const data = await response.json();
		  if (data.success) {
			setSuccess("Usuário registrado com sucesso!");
			setError(""); // Limpa o estado de erro
			setTimeout(() => {
			  navigate("/"); // Redireciona após 2 segundos
			}, 2000);
		  } else {
			setError(data.message || "Erro ao realizar o cadastro.");
			setSuccess(""); // Limpa o estado de sucesso
		  }
		} else {
		  const textResponse = await response.text();
		  setError(textResponse || "Erro ao realizar o cadastro.");
		  setSuccess(""); // Limpa o estado de sucesso
		}
	  } catch (error) {
		console.error("Erro ao enviar os dados:", error);
		setError("Erro ao realizar o cadastro. Tente novamente mais tarde.");
		setSuccess(""); // Limpa o estado de sucesso
	  }
	};

	return (
		// import ptMessages from '../locales/pt.json';
		// import enMessages from '../locales/en.json';
		// import esMessages from '../locales/es.json';

		// const messages = {
		//     pt: ptMessages,
		//     en: enMessages,
		//     es: esMessages,
		//   };

		<div id="login-page">
			<div className="cadastro-form-div">
				<form onSubmit={handleSubmit}>
					<img src={LogoLogin} alt="" />
					<h2>Cadastrar novo usuário.</h2>
					<div className="row">
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="username">Nome de Usuario</label>
								<input
									type="text"
									id="username"
									name="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="dataNascimento">Data de Nascimento</label>
								<input
									required
									type="text"
									id="dataNascimento"
									name="dataNascimento"
									value={DateBirth}
									onChange={(e) => setDateBirth(e.target.value)}
								/>
							</div>
						</div>
						
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="firstName">Primeiro Nome</label>
								<input
									required
									type="text"
									id="firstName"
									name="firstName"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
						</div>
					
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="lastName">Sobrenome</label>
								<input
									required
									type="text"
									id="lastName"
									name="lastName"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 col-md-3">
							<div>
								<label htmlFor="ContryCelPhone">Pais</label>
								<input
									required
									type="text"
									id="ContryCelPhone"
									name="ContryCelPhone"
									value={contryCelPhone}
									onChange={(e) => setContryCelPhone(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 col-md-9">
							<div>
								<label htmlFor="celular">Celular</label>
								<input
									required
									type="text"
									id="celular"
									name="celular"
									value={CelPhone}
									onChange={(e) => setCelPhone(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 col-md-3">
							<div>
								<label htmlFor="typyDocument">Tipo de Documento</label>
								<input
									required
									type="text"
									id="typyDocument"
									name="typyDocument"
									value={typeDocument}
									onChange={(e) => setTypeDocument(e.target.value)}
								/>
							</div>
						</div>
					
						<div className="col-12 col-md-9">
							<div>
								<label htmlFor="cpf">CPF</label>
								<input
									required
									type="text"
									id="cpf"
									name="cpf"
									value={NumDocument}
									onChange={(e) => setNumDocument(e.target.value)}
								/>
							</div>
						</div>

						
					
						<div className="col-12 ">
							<div>
								<label htmlFor="email">Email</label>
								<input
									required
									type="email"
									id="email"
									name="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 ">
							<div>
								<label htmlFor="password">Senha</label>
								<input
									required
									type="password"
									id="password"
									name="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 ">
							<div>
								<label htmlFor="password">Confirma a Senha</label>
								<input
									required
									type="password"
									id="confirmPassword"
									name="confirmPassword"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12">
							<div>
								<label htmlFor="nome">Idioma Preferido</label>
								<select
									required
									id="prefLanguage"
									name="prefLanguage"
									value={prefLanguage}
									onChange={(e) => setPrefLanguage(e.target.value)}
								>
									<option value="pt">Português</option>
									<option value="es">Espanhol</option>
									<option value="en">Inglês</option>
								</select>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<a href="/" className="voltar" value="Entrar">
								Voltar ao login
							</a>
						</div>
						<div className="col-12 col-md-6">
							<input type="submit" className="entrar" value="Criar sua conta" />
						</div>

						<div className="col-12">
							{error && <p className="error">{error}</p>}
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Register;
