import React from "react";
import logoHeader from "../assets/images/logo-header.png";
import menuHeaderIcon from "../assets/images/menu-header-icon.png";
import user from "../assets/images/user.png";
import Meu_Cadastro from "../assets/images/Meu_Cadastro.png";
import Alterar_Senha from "../assets/images/Alterar_Senha.png";
import Editar_Foto_Perfil from "../assets/images/Editar_Foto_Perfil.png";
import brazil from "../assets/images/brazil.png";
import mexico from "../assets/images/mexico.png";
import usa from "../assets/images/usa.png";
import Ajuda from "../assets/images/Ajuda.png";
import Sair from "../assets/images/sair.png";
import home from "../assets/images/home.png";

import { FormattedMessage } from "react-intl";

const Header = ({
	isUserMenuActive,
	toggleUserMenu,
	switchLanguage,
	userName,
	userRole,
}) => {
	return (
		<div>
			<div className="header">
				<img src={logoHeader} className="img-fluid" width="100" alt="Logo" />
				<div className="d-flex align-items-center position-relative">
					<p className="data m-0">15 nov 2024</p>
					<img
						onClick={toggleUserMenu}
						src={menuHeaderIcon}
						width="20"
						className="menu-header-icon img-fluid"
						alt="Icone do menu"
					/>
					<img
						src={user}
						width="25"
						className="user-img img-fluid"
						alt="Usuario"
					/>
					<div className="nome-usuario">
						<h3 className="m-0">{userName}</h3>
						<small className="d-block">{userRole}</small>
					</div>

					<div className={`user-menu ${isUserMenuActive ? "active" : ""}`}>
						<ul>
							<li>
								<img src={Meu_Cadastro} alt="" />{" "}
								<FormattedMessage id="meu_cadastro" />
							</li>
							<li>
								<img src={Alterar_Senha} alt="" />{" "}
								<FormattedMessage id="alterar_senha" />
							</li>
							<li>
								<img src={Editar_Foto_Perfil} alt="" />{" "}
								<FormattedMessage id="alterar_foto_perfil" />
							</li>
							<li className="idioma">
								<FormattedMessage id="idioma" />
								<img
									src={brazil}
									alt="PT-br"
									onClick={() => switchLanguage("pt")}
								/>
								<img
									src={mexico}
									alt="ES"
									onClick={() => switchLanguage("es")}
								/>
								<img
									src={usa}
									alt="EN-us"
									onClick={() => switchLanguage("en")}
								/>
							</li>
						</ul>
						<hr />
						<ul>
							<li>
								<img src={Ajuda} alt="" /> <FormattedMessage id="ajuda" />
							</li>
							<li>
								<a className="sair" href="index.html">
									<img src={Sair} alt="" /> <FormattedMessage id="sair" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			
		</div>
	);
};

export default Header;
