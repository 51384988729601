import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dash from './pages/dash';
import Login from './pages/login';
import Register from './pages/register';
import Prescricao from "./pages/Prescricao";
import CriarPrescricao from "./pages/CriarPrescricao";
import CriarOrdemProducao from "./pages/CriarOrdemProducao";
import OrdemProducao from "./pages/OrdemProducao";

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Rota para a página de login */}
        <Route path="/" element={<Login />} />

        {/* Rota para a página de cadastro */}
        <Route path="/cadastro" element={<Register />} />

        {/* Rota principal /dash com conteúdo dinâmico no Outlet */}
        <Route path="/dash" element={<Dash />}>
          <Route index element={<OrdemProducao />} />  {/* Rota padrão de /dash */}
          <Route path="prescricao" element={<Prescricao />} />  {/* Rota para /dash/prescricao */}
          <Route path="criar-prescricao" element={<CriarPrescricao />} />  {/* Rota para /dash/criarPrescricao */}
          <Route path="criar-ordem-producao" element={<CriarOrdemProducao />} />  {/* Rota para /dash/criarPrescricao */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;